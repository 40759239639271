<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <!-- <v-btn block class="mb-2">
            Sign In
          </v-btn> -->
          <v-btn block class="primary" @click="signout">
            Sign Out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-btn
      class="d-md-none drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar app height="60px;">
      <v-container class="py-0 px-0 px-sm-2 fill-height" style="margin-left: 0; margin-right: 0;">
        <router-link to="/" class="d-flex align-center text-decoration-none mr-2">
          <img src="/images/BOP-Plus-Logo.jpg" alt="" height="48" class="mr-1">
          <img src="/images/logo.png" alt="" height="48" class="mr-1">
          <span>Your complete ophthalmology service partner &nbsp;&nbsp;</span>
        </router-link>
        <v-spacer></v-spacer>
        <router-link to="/" class="d-flex align-center text-decoration-none mr-2">
          <span class="font-weight-black ">
            Today - {{new Date() | formatDate('dd MMM yyyy') }}
          </span>
        </router-link>
        <a href="mailto:info@injtrack.com">info@injtrack.com</a>
        <!-- <v-btn text class="mx-1 d-none d-sm-inline-block" on="signin">
    Sign In
  </v-btn> -->
        <v-btn color="primary" class="d-none d-sm-inline-block" v-if="isUser" @click="signout">
          Sign Out
        </v-btn>
        <v-btn color="primary" class="d-none d-sm-inline-block" @click="gotoCodes" v-if="isAdminAndPageRight">
          Activation 
        </v-btn>
      </v-container>
    </v-app-bar>
  </div>
</template>


<script>
  import Vue from 'vue';
  export default {
    created() {
      var user = Vue.prototype.$getCookie('user');
      this.isAdmin = user === 'admin';
      this.isUser = user != null;
      this.isAdminAndPageRight = this.isAdmin && this.$route.path != '/codes';
      this.checkValidAsync();
    },
    data: (vm) => ({
      menu: false,
      drawer: false,
      nowData: new Date().toISOString().substr(0, 10),
      isAdmin: false,
      isAdminAndPageRight: false
    }),
    methods: {
      signout() {
        this.$setCookie('user', null, -1);
        this.$router.push('/signin');
      },
      gotoCodes() {
        this.$router.push('/codes');
      },
      async checkTokenValid() {

        this.isDisabled = true;
        const http = require('https');

        return new Promise((resolve, reject) => {
          const options = {
            hostname: this.$serverAddress,
            port: this.$serverPort,
            path: '/api/users/check-token',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.$getCookie('token')
            }
          };
          let responseBody = '';

          const req = http.request(options, (res) => {
            console.log(`status code: ${res.statusCode}`);
            if (res.statusCode != 200) {
              this.$setCookie('user', null);
              this.$setCookie('token', null);
              resolve(false);
              return;
            }
            resolve(true);
            return;
          });
          req.end();
        });
      },
      async checkValidAsync() {
        if (!this.$route.meta.requiresAuth)
          return;
        let valid = false;
        try {
          valid = await this.checkTokenValid();
        }
        catch (error) {
          valid = false;
          console.log(valid);
        }
        if (!valid) {
          this.$router.push('/signin');
        }
      }
    }
  }
</script>
<style scoped>
.drawer-button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 6;
}
  .d-sm-inline-block {
    margin: 0px 10px;
  }
</style>
