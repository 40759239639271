<template>
  <v-container class="py-6">
    <div v-if="hasAccess">
      <br />
      <div class="mt-4">
        <div class="d-flex flex-column flex-sm-row w-full w-lg-half">
          <v-text-field outlined
                        solo
                        label="Quantity"
                        height="64"
                        v-model="numberInput"
                        @input="validateInput"
                        maxlength="3"
                        class="mr-sm-2"></v-text-field>
          <v-text-field outlined
                        solo
                        label="Company"
                        height="64"
                        v-model="company"
                        class="mr-sm-2"></v-text-field>
          <v-btn x-large color="secondary" @click="genCodes()">Generate</v-btn>
        </div>
      </div>

      <h1 class="text-h4">All Activation codes (click to copy)</h1>
      <v-card class="my-1">
        <v-data-table :headers="headers"
                      :items="codes"
                      class="flex-grow-1">

          <template v-slot:item.company="{ item }">
            <div class="d-flex align-center py-1">
              <div class="ml-1 caption font-weight-bold" @click="copyCode(item.code)">
                {{ item.company  }}
              </div>
            </div>
          </template>

          <template v-slot:item.code="{ item }">
            <div class="font-weight-bold"><a @click="copyCode(item.code)">{{ item.code }}</a></div>
          </template>

          <template v-slot:item.is_used="{ item }">
            <v-icon v-if="item.is_used" small color="success">
              mdi-check-circle
            </v-icon>
            <v-icon v-else small>
              mdi-circle-outline
            </v-icon>
          </template>

          <template v-slot:item.deleteBtn="{ item }">
            <div class="actions">
              <v-btn icon @click="deleteCode(item.code)">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-else>
      <h1 class="text-h4">{{ errorMessage }}</h1>
    </div>
  </v-container>
</template>

<script>
import config from '@/configs'

  export default {
    created() {
      this.getCodes()
    },
  components: {
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config,
      drawer: null,
      isContentBoxed: false,
      codes: [],
      company: '',
      numberInput: '1',
      headers: [
        { text: 'Company', align: 'left', value: 'company' },
        { text: 'Code', value: 'code' },
        { text: 'Used', value: 'is_used' },
        { text: 'Delete', align: 'right', value:'deleteBtn' }
      ],
      hasAccess: true,
      errorMessage: ''
 }
  },
    watch: {
      selectedUsers(val) {
        console.log(val);
      }
    },
   methods: {
     getCodes() {
       const http = require('https');
       return new Promise((resolve, reject) => {
         const options = {
           hostname: this.$serverAddress,
           port: this.$serverPort,
           path: '/api/invitation/invitation-codes',
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + this.$getCookie('token')
           }
         };
         let responseBody = '';

         const req = http.request(options, (res) => {
           console.log(`status code: ${res.statusCode}`);
           if (res.statusCode === 403) {
             this.errorMessage = "403 - No access";
             this.hasAccess = false; 
             setTimeout(() => {
               this.$router.push('/');
             }, 3000); 
             reject(this.errorMessage);
             return;
           }
           res.on('data', (d) => {
             responseBody += d;
           });
           res.on('error', (e) => {
             this.errorMessage = 'Errors occurred, please contact your admin';
             reject(e.message);
           });
           res.on('end', () => {
             try {
               if (res.statusCode === 200) {
                 const parsedData = JSON.parse(responseBody);
                 this.codes = parsedData["data"];
                 resolve(parsedData["data"]);
                 console.log(responseBody);
               } else {
                 console.error(responseBody);
                 this.errorMessage = 'Errors occurred, please contact your admin';
                 reject(responseBody);
               }
             } catch (e) {
               console.error(e.message);
               this.errorMessage = 'Errors occurred, please contact your admin';
               reject(e.message);
             }
           });
         });
         req.end();
       });
     },

      genCodes() {
        this.isDisabled = true;
        const http = require('https');
        const data = JSON.stringify({
          n: this.numberInput,
          company: this.company,
        });
        const options = {
          hostname: this.$serverAddress,
          port: this.$serverPort,
          path: '/api/invitation/generate',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Content-Length': data.length,
            'Authorization': 'Bearer ' + this.$getCookie('token')
          }
        };
        let responseBody = '';

        const req = http.request(options, (res) => {
          console.log(`status code: ${res.statusCode}`);
          if (res.statusCode == 200) {
            this.$setCookie('user', this.username);
            this.$router.push('/');

          }
          else if (res.statusCode == 401) {
          }
          res.on('data', (d) => {
            responseBody += d;
          });
          res.on('error', (e) => {
            this.errorMessage = 'Errors occurred, please contact your admin';
          });
          res.on('end', () => {
            try {
              if (res.statusCode != 200)
                console.log(responseBody);
              window.location.reload();
              // this.$router.push('/signin');
            } catch (e) {
              console.error(e.message);
              this.errorMessage = 'Errors occurred, please contact your admin';
            }
          });
        });
        // 写入数据到请求主体
        req.write(data);
        req.end();
      },
      copyCode(text) {
        navigator.clipboard.writeText(text)
          .then(() => {
            console.log('Text copied to clipboard successfully!');
            // 可选：显示用户反馈，例如通知
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
            // 可选：处理复制失败的情况，例如显示错误消息
          });
     },
     deleteCode(text) { console.log('fake delete',text); },

      validateInput(value) {
        const regex = /^\d*$/;
        if (regex.test(value)) {
          this.trimNumber(value);
        } else {
          this.$nextTick(() => {
            let v = this.numberInput.replace(/[^0-9]/g, '');
            this.trimNumber(v);
          });
        }
      },
      trimNumber(value) {
        let v = parseInt(value);
        if (isNaN(v))
          this.numberInput = '';
        else {
          v = Math.max(Math.min(v,100), 1);
          this.numberInput = v.toString();
        }
        console.log(this.numberInput);
      }
    }
}
</script>

<style lang="scss" scoped>
  .app-bar-full {
    .v-toolbar__content,
    .v-toolbar__extension {
      padding: 0;
    }

  }
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
