<template>
  <div>
    <EITHeader />
    <EITInvitationCode />
    <EITFooter />
  </div>
</template>

<script>
  import EITHeader from '@/components/eye/EITHeader.vue'
 import EITInvitationCode from '@/components/eye/EITInvitationCode.vue'
  import EITFooter from '@/components/eye/EITFooter.vue'

export default {
  components: {
    EITHeader,
    EITInvitationCode,
    EITFooter
 }
}
</script>
